@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');

*{margin:0; padding:0; box-sizing: border-box;}
html, body, #root {
	height: 100%;
	width: 100%;
  	background-color: #ffddf6;
	text-align: center;
	align-content: center;
	font-family: "Black Ops One";
	overflow: hidden;
}

#err {
	background-color: #ff6d6d;
	color: #ffffff;
	padding: 5px;
	border-style: solid;
	border-radius: 8px;
	border-width: 1px;
	border-color: #ff4545;
	margin-bottom: 10px; 
}

#loginContainer {
	background-color: #88304E;
	width: 20%;
	min-height: 35%;
	margin: auto;
	padding: 1%;
	border-radius: 5px;
	animation: fadeIn .25s;
	color: white;
}

.toggleLink {
	color: white;
	text-decoration: underline;
}

.toggleLink:hover {
	color: #afafaf;
	cursor: pointer;
}

#loginContainer h1 {
	font-size: 2vw;
}

#loginContainer span {
	font-size: 1vw;
}

#loginContainer input {
	text-align: center;
	background-color: rgb(236, 241, 236);
	border: 2px solid transparent;
	border-radius: 3px;
	font-size: 16px;
	width: 85%;
	transition: border .5s;
}

#loginContainer .loginBtn {
	border: 2px solid transparent;
	background: #272727;
	color: #ffffff;
	font-size: 16px;
	line-height: 25px;
	padding: 10px 0;
	text-decoration: none;
	text-shadow: none;
	border-radius: 3px;
	box-shadow: none;
	transition: 0.25s;
	display: block;
	min-width: 50%;
	margin: 10px auto;
	cursor: pointer;
}

.topNav {
  	position: sticky;
	width: 100%;
	justify-content: center;
	display: inline-block;
	font-size: 0;
	overflow: hidden;
}

.topNav .temp {
	background-color: #ffffff;
	display: inline-block;
	font-size: 2vw;
	width: 100%;
}

.name {
	transform: translateX(6%);
	font-family: "Black Ops One";
	float: left;
}

.topNav button {
	width: 6%;
	margin: 1%;
	font-size: medium;
	float: right;
}

.contentContainer {
	height: 100%;
	width: 100%;
}

.cartContainer {
	width: 25%;
	float: right;
	height: 100%;
	background-color: #ffbecf;
	position: absolute;
	right:0;
	bottom:0;
}

.cartContainer div {
	margin: 1%;
}

.cartContainer .exit {
	position: absolute;
	left: 1%;
	top: 1%;
}

.productsContainer {
  display: flex;
  float: center;
  flex-wrap: wrap;
  flex-direction: row;
  flex-direction: row;
  margin: 2%;
}

.product {
  background-color:#ffbced;
  border-radius: 5px;
  margin: 1%;
  width: 30%;
  padding: 2%;
  flex-grow: 1;
  height: auto;
  max-width: 33%;
}

.login-with-google-btn {
	transition: background-color .3s, box-shadow .3s;
		
	padding: 12px 16px 12px 42px;
	border: none;
	border-radius: 3px;
	box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

	color: #757575;
	font-size: 14px;
	font-weight: 500;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;

	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
	background-color: white;
	background-repeat: no-repeat;
	background-position: 12px 11px;
	min-width: 50%;
}

.login-with-google-btn:hover {
	box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
	cursor: pointer;
}

.login-with-google-btn:active {
	background-color: #eeeeee;
}